import './style.css'
import './fontawesome.css';
import 'animate.css';
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import TypeIt from "typeit";

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';

import { RGBShiftShader } from 'three/examples/jsm/shaders/RGBShiftShader.js';
import { DotScreenShader } from 'three/examples/jsm/shaders/DotScreenShader.js';
import { GammaCorrectionShader } from "three/examples/jsm/shaders/GammaCorrectionShader.js";

/**
 * Base
 */
// Debug
//const gui = new dat.GUI()


document.getElementById( 'loaderbar' ).style.width = '80%';

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Models
 */
const dracoLoader = new DRACOLoader()
dracoLoader.setDecoderPath('/draco/')

const gltfLoader = new GLTFLoader()
gltfLoader.setDRACOLoader(dracoLoader)

let mixer = null
let face = null;
gltfLoader.load(
    //'/models/Fox/glTF/Fox.gltf',
    '/models/untitledraw.glb',
    (gltf) =>
    {
        gltf.scene.position.set( 0, -1.115, 1.22 );
        /*

        gltf.scene.position.set( 0, -4.8, 0.3 );
        */
        gltf.scene.rotation.x = Math.random() - 1.5;
        gltf.scene.rotation.y = Math.random() - 0.5 * 1;

        gltf.scene.scale.set( 1, 1.1, 1 );

        scene.add(gltf.scene)


        const material = new THREE.MeshPhongMaterial( { color: 0xffffff, flatShading: true, transparent: false } );
        gltf.scene.children[ 0 ].material = material;
        face = gltf.scene;


        // Loading done
        document.getElementById( 'loaderbar' ).style.width = '100%';
        setTimeout( () =>
        {
            document.getElementById( 'loader' ).style.opacity = 0;
            //document.getElementById( 'loaderbar' ).style.height = '100vh';
            setTimeout( () =>
            {
                document.getElementById( 'loader' ).remove();
            }, 250 );

        }, 250 );

        // Experiment
        /*

        var mat_wireframe = new THREE.MeshBasicMaterial({color: 0x000000, wireframe: true});
        var mat_lambert = new THREE.MeshLambertMaterial({color: 0xffffff, shading: THREE.FlatShading});
        var meshmaterials = [ mat_wireframe, mat_lambert ];
        var myMesh =createMultiMaterialObject( gltf.scene.children[ 0 ].geometry, meshmaterials );
        scene.add( myMesh ) ;
        face = myMesh;
         */
        /*

        https://stackoverflow.com/questions/21219971/three-js-wireframe-renderd-behind-shadermaterial

        const geo = new THREE.EdgesGeometry( gltf.scene.children[ 0 ].geometry, 1 );
        const mat = new THREE.LineBasicMaterial( { color: 0xff0000, linewidth: 2 } );
        const wireframe = new THREE.LineSegments( geo, mat );
        scene.add( wireframe );
        face = wireframe;
         */

        /*
        const geo = new THREE.WireframeGeometry( gltf.scene.children[ 0 ].geometry );
        const mat = new THREE.LineBasicMaterial( { color: 0xff0000, transparent: true} );
        const wireframe = new THREE.LineSegments( geo, mat );
        scene.add( wireframe );
        face = wireframe;
*/

    }
)

/**
 * Floor
 */
const floor = new THREE.Mesh(
    new THREE.PlaneGeometry(10, 10),
    new THREE.MeshStandardMaterial({
        color: '#444444',
        metalness: 0,
        roughness: 0.5
    })
)
floor.receiveShadow = true
floor.rotation.x = - Math.PI * 0.5
//scene.add(floor)

/**
 * Lights
 */
const ambientLight = new THREE.AmbientLight(0xffffff, 0.8)
scene.add(ambientLight)

const directionalLight = new THREE.DirectionalLight(0xffffff, 0.6)
directionalLight.castShadow = true
directionalLight.shadow.mapSize.set(1024, 1024)
directionalLight.shadow.camera.far = 15
directionalLight.shadow.camera.left = - 7
directionalLight.shadow.camera.top = 7
directionalLight.shadow.camera.right = 7
directionalLight.shadow.camera.bottom = - 7
directionalLight.position.set(- 5, 5, 0)
scene.add(directionalLight)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

    // Update effect composer
    composer.setSize(sizes.width, sizes.height);
    composer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.set(0, 0.7, Math.PI * 0.5)
scene.add(camera)

// Controls
/*
const controls = new OrbitControls(camera, canvas)
controls.target.set(0, 0.75, 0)
controls.enableDamping = true
+/
/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true,
    alpha: true
})
// renderer.shadowMap.enabled = true
// renderer.shadowMap.type = THREE.PCFSoftShadowMap
// renderer.outputEncoding = THREE.sRGBEncoding;
// renderer.toneMapping = THREE.ReinhardToneMapping;
// renderer.toneMappingExposure = 1.5;
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

// postprocessing

// Note:
// This keeps the antialisasing intact (samples = 2)
// The width and height are dummy values and are autoamtically updated by the composer
const renderTarget = new THREE.WebGLRenderTarget(
    1024,
    1024,
    {
        samples: renderer.getPixelRatio() === 1 ? 2 : 0//0
    }
);

const composer = new EffectComposer( renderer);
composer.addPass( new RenderPass( scene, camera ) );

const effect1 = new ShaderPass( DotScreenShader );
effect1.uniforms[ 'scale' ].value = 4;
composer.addPass( effect1 );

const effect2 = new ShaderPass( RGBShiftShader );
effect2.uniforms[ 'amount' ].value = 0.0015;
composer.addPass( effect2 );

//Corrects the color
const finalPass = new ShaderPass( GammaCorrectionShader );
composer.addPass( finalPass );

/*
const glitchPass = new GlitchPass(1024);
composer.addPass( glitchPass );
console.log( glitchPass );
*/

let mouseX = 0, mouseY = 0;
const onDocumentMouseMove = (event) =>
{
    mouseX = event.clientX - window.innerWidth * 0.5;
    mouseY = event.clientY - window.innerHeight * 0.5;
};

document.addEventListener("pointermove", onDocumentMouseMove, false);

/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime

    if ( face )
    {
        face.rotation.y += 0.01 * (mouseX * 0.00025 - face.rotation.y);
        face.rotation.x += 0.01 * (mouseY * 0.0001 - face.rotation.x);
        face.rotation.z = -face.rotation.y * 0.25;
    }
    // Model animation
    /*
    if(mixer)
    {
        mixer.update(deltaTime)
    }
     */

    // Update controls
    //controls.update()

    // Render
    //renderer.render(scene, camera)
    composer.render();

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()


setTimeout( function()
{
    const random = (min, max) => min + Math.floor(Math.random() * (max - min + 1));
    let crossBarGlitchTexts = document.querySelectorAll(".cross-bar-glitch");
    crossBarGlitchTexts.forEach(text => {
        let content = text.textContent;
        text.textContent = "";
        // Glitch Text
        let slice = text.dataset.slice;
        let glitchText = document.createElement("div");
        glitchText.className = "glitch";
        glitchText.style.setProperty("--slice-count", slice);
        for (let i = 0; i <= Number(slice); i++) {
            let span = document.createElement("span");
            span.textContent = content;
            span.style.setProperty("--i", `${i + 1}`);
            if (i !== Number(slice)) {
                span.style.animationDelay = `${800 + random(100, 300)}ms`;
            }
            glitchText.append(span);
        }
        text.appendChild(glitchText);
        // Cross Bars
        let bars = document.createElement("div");
        bars.className = "bars";
        for (let i = 0; i < 5; i++) {
            let bar = document.createElement("div");
            bar.className = "bar";
            bars.append(bar);
        }
        text.append(bars);
        text.style.display = 'inline-block';
    });

    // Fix bug of bars by hiding them after animation is done
    setTimeout( () =>
    {
        document.querySelector( '.bars' ).style.display = 'none';
    }, 1000 );

}, 2000 );

document.querySelectorAll( 'footer div' ).forEach( ( elem, i ) =>
{
    setTimeout( () =>
    {
        elem.classList.add( 'fadeIn' );
    }, i * 300 + 2000 );
});

setTimeout( () =>
{
    document.querySelector( '.avatar' ).classList.add( 'fadeIn' );
    document.querySelector( '[data-id="btnShowInfo"]' ).addEventListener( 'click', () =>
    {
        const elem = document.querySelector( '[data-id="btnShowInfo"]' );
        if ( elem.classList.contains( 'pressed' ) )
        {
            elem.classList.remove( 'pressed' );
            document.querySelector( '.work-container' ).classList.remove( 'expanded' );
        }
        else
        {
            elem.classList.add( 'pressed' );

            document.querySelector( '.work-container' ).classList.add( 'expanded' );
        }
    });
}, 3000 );

setTimeout( () =>
{
    new TypeIt(
        '#typer',
        {
            /*
            strings: [
                'creative fullstack developer',
                'owner of aheadware gmbh',
                'founder of superplug.in',
                'creator of InfrontJS',
                'nerd, freak, demoscener, coder',
                'solopreneur'
            ],
             */
            loop: true,
            startDelay: 500,
            breakLines: false
        }
    )
        .type( "creative fullstack developer" )
        .exec( () => { document.querySelectorAll( '.fadeIn' ).forEach( ( elem ) => { elem.classList.remove( 'animate__animated', 'animate__flash'  ); }  ); } )
        .pause( 1000 )
        .delete()
        .type( "owner of aheadware gmbh" )
        .pause( 1000 )
        .delete()
        .type( "founder of superplug.in" )
        .pause( 1000 )
        .delete()
        .type( "inventor of InfrontJS" )
        .pause( 1000 )
        .delete()
        /*
        .type( "creator of backyart.com" )
        .pause( 800 )
        .delete()
         */
        .type( 'digital nerd & tech enthusiast')
        .pause( 1000 )
        .delete()
        .type( 'indiehacker & solopreneur' )
        .pause( 1000 )
        .delete()
        .type( 'follow me' )
        .exec( () => { document.querySelector( '.fadeIn:first-child' ).classList.add( 'animate__animated', 'animate__flash' );  })
        .pause( 800 )
        .move( -3 )
        .delete( 7 )
        .type( 'fork')
        .exec( () => { document.querySelector( '.fadeIn:nth-child(2)' ).classList.add( 'animate__animated', 'animate__flash' );  })
        .pause( 800 )
        .delete( 4 )
        .type( 'work with' )
        .exec( () => { document.querySelector( '.fadeIn:nth-child(3)' ).classList.add( 'animate__animated', 'animate__flash' );  })
        .pause( 800 )
        .delete( 9 )
        .type( 'contact' )
        .exec( () => { document.querySelector( '.fadeIn:nth-child(4)' ).classList.add( 'animate__animated', 'animate__flash' );  })
        .pause( 800 )
        .move( 3 )
        .pause( 800 )
        .delete()
        .go();

}, 3500 );
